import axios from 'axios';
import { Api, Url, Crypto } from '@/utils/';

export default class AutoReply {
    static get () {
        const constructedURL = Url.get('auto_replies') + Url.getAccountParam();
        const promise = new Promise((resolve, reject) => {
            Api.fetchResourcesInChunks(constructedURL, 100, Crypto.decryptAutoReply).then(resolve).catch(reject);
        });
        return promise;
    }

    static delete(id) {
        let constructed_url = Url.get('remove_auto_reply') + id + Url.getAccountParam();
        axios.post(constructed_url);
    }
}
