import store from '@/store';
import { Util } from '@/utils/';

export default class Url {
    static urls = {
        login: 'accounts/login/',
        update_setting: 'accounts/update_setting',
        get_personal_identifiable_data: 'accounts/personal_identifiable_data',
        messages: 'messages/',
        remove_message: 'messages/remove/',
        add_message: 'messages/add/',
        new_thread: 'messages/forward_to_phone',
        folders: 'folders/',
        remove_folder: 'folders/remove/',
        conversations: 'conversations/',
        conversation: 'conversations/',
        update_conversation: 'conversations/update/',
        read: 'conversations/read/',
        archive: 'conversations/archive/',
        unarchive: 'conversations/unarchive/',
        delete: 'conversations/remove/',
        dismiss: 'accounts/dismissed_notification/',
        settings: 'accounts/settings/',
        websocket: 'stream',
        media: 'media/',
        contacts: 'contacts/simple/',
        remove_contact: 'contacts/remove_ids/',
        blacklists: 'blacklists',
        remove_blacklist: 'blacklists/remove/',
        create_blacklist: 'blacklists/add/',
        scheduled: 'scheduled_messages',
        remove_scheduled: 'scheduled_messages/remove/',
        create_scheduled: 'scheduled_messages/add/',
        account_stats: 'accounts/count',
        drafts: 'drafts',
        drafts_conversation: 'drafts/',
        create_drafts: 'drafts/add/',
        remove_drafts: 'drafts/remove/',
        replace_drafts: 'drafts/replace/',
        devices: 'devices',
        remove_device: 'devices/remove/',
        templates: 'templates',
        create_template: 'templates/add/',
        update_template: 'templates/update/',
        remove_template: 'templates/remove/',
        auto_replies: 'auto_replies',
        remove_auto_reply: 'auto_replies/remove/'
    }

    static getBaseUrl () {
        return process.env.VUE_APP_SERVER_BASE_URL;
    }

    static getApiVersion () {
        return 'api/v1/';
    }

    static getAccountParam () {
        return '?account_id=' + store.state.account_id + '&' + Url.getXUserTokenParam();
    }

    static getXUserTokenParam () {
        return 'X-User-Token=' + Util.xUserToken();
    }

    static getXUserTokenPayload () {
        return {
            'X-User-Token': Util.xUserToken()
        };
    }

    static getAccountPayload () {
        return {
            account_id: store.state.account_id,
            'X-User-Token': Util.xUserToken()
        };
    }

    static get (name) {
        let protocol = 'https://';
        if (name == 'websocket') { protocol = 'wss://' }

        return protocol + Url.getBaseUrl() + Url.getApiVersion() + Url.urls[name];
    }
}
