import axios from 'axios';
import store from '@/store/';
import { Api, Url, Crypto } from '@/utils/';

export default class Blacklist {
    static get () {
        const constructedURL = Url.get('blacklists') + Url.getAccountParam();
        const promise = new Promise((resolve, reject) => {
            Api.fetchResourcesInChunks(constructedURL, 100, Crypto.decryptBlacklist).then(resolve).catch(reject);
        });
        return promise;
    }

    static delete(id) {
        let constructed_url = Url.get('remove_blacklist') + id + Url.getAccountParam();
        axios.post(constructed_url);
    }

    static create = {
        phone: (number) => {
            let request = {
                account_id: store.state.account_id,
                device_id: Api.generateId(),
                phone_number: Crypto.encrypt(number)
            };

            request = { ...request, ...Url.getXUserTokenPayload() };

            let constructed_url = Url.get('create_blacklist');

            const promise = new Promise((resolve) => {
                axios.post(constructed_url, request, { 'Content-Type': 'application/json' })
                    .then(response => { resolve(response) });
            });

            return promise;
        },
        phrase: (phrase) => {
            let request = {
                account_id: store.state.account_id,
                device_id: Api.generateId(),
                phrase: Crypto.encrypt(phrase)
            };

            request = { ...request, ...Url.getXUserTokenPayload() };

            let constructed_url = Url.get('create_blacklist');

            const promise = new Promise((resolve) => {
                axios.post(constructed_url, request, { 'Content-Type': 'application/json' })
                    .then(response => { resolve(response) });
            });

            return promise;
        }
    }

}
