import axios from 'axios';
import store from '@/store/';
import { Api, Url, Crypto } from '@/utils/';

export default class ScheduledMessages {
    static get () {
        const constructedURL = Url.get('scheduled') + Url.getAccountParam();
        const promise = new Promise((resolve, reject) => {
            Api.fetchResourcesInChunks(constructedURL, 100, Crypto.decryptScheduledMessage)
                .then(resolve)
                .catch(reject);
        });
        return promise;
    }

    static delete(id) {
        let constructed_url = Url.get('remove_scheduled') + id + Url.getAccountParam();
        axios.post(constructed_url);
    }

    static create(to, message, time, title, repeat) {
        let request = {
            account_id: store.state.account_id,
            device_id: Api.generateId(),
            to: Crypto.encrypt(to),
            data: Crypto.encrypt(message),
            mime_type: Crypto.encrypt("text/plain"),
            title: Crypto.encrypt(title.trim().length == 0 ? to : title.trim()),
            timestamp: time,
            repeat: repeat
        };

        request = { ...request, ...Url.getXUserTokenPayload() };

        let constructed_url = Url.get('create_scheduled');

        const promise = new Promise((resolve) => {
            axios.post(constructed_url, request, { 'Content-Type': 'application/json' })
                .then(response => { resolve(response) });
        });

        return promise;
    }
}
